import React from "react";
import { Menu, Layout } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

import {
  StockOutlined,
  WalletOutlined,
  InfoCircleOutlined,
  FundViewOutlined,
  AppstoreOutlined,
  TeamOutlined,
  TagOutlined,
  InboxOutlined,
  ShopOutlined,
  CarOutlined,
  CompassOutlined,
  IdcardOutlined,
  ShoppingCartOutlined,
  ApartmentOutlined,
  ContainerOutlined,
  AppstoreAddOutlined,
  MoneyCollectOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import logo from "../../logo.svg";

const { Sider } = Layout;

const Sidebar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { calculate } = props;
  const [collapsed, setCollapsed] = React.useState(false);

  let onCollapse = (collap) => {
    setCollapsed(collap);
  };

  let sidebars = [
    {
      item: {
        key: "/",
        icon: <AppstoreOutlined />,
        label: "Dashboard",
        onClick: (e) => navigate("/"),
      },
      perms: ["super_admin"],
    },
    {
      item: {
        key: "/banner",
        icon: <FundViewOutlined />,
        label: "Banner",
        onClick: (e) => navigate("/banner"),
      },
      perms: ["banner_read"],
    },
    {
      item: {
        key: "/category",
        icon: <ApartmentOutlined />,
        label: "Category",
        onClick: (e) => navigate("/category"),
      },
      perms: ["category_read"],
    },
    {
      item: {
        key: "/sub-category",
        icon: <ApartmentOutlined />,
        label: "Sub Category",
        onClick: (e) => navigate("/sub-category"),
      },
      perms: ["sub_category_read"],
    },
    {
      item: {
        key: "/package",
        icon: <InboxOutlined />,
        label: "Package",
        onClick: (e) => navigate("/package"),
      },
      perms: ["package_read"],
    },
    {
      item: {
        key: "/material",
        icon: <AppstoreOutlined />,
        label: "Material",
        onClick: (e) => navigate("/material"),
      },
      perms: ["material_read"],
    },
    {
      item: {
        key: "/type",
        icon: <AppstoreAddOutlined />,
        label: "Type",
        onClick: (e) => navigate("/type"),
      },
      perms: ["type_read"],
    },
    {
      item: {
        key: "/spec",
        icon: <UnorderedListOutlined />,
        label: "Spec",
        onClick: (e) => navigate("/spec"),
      },
      perms: ["spec_read"],
    },
    {
      item: {
        key: "/unit",
        icon: <StockOutlined />,
        label: "Unit",
        onClick: (e) => navigate("/unit"),
      },
      perms: ["spec_read"],
    },
    {
      item: {
        key: "/vehicle-menu",
        icon: <CarOutlined />,
        label: "Vehicle",
        children: [
          {
            key: "/vehicle",
            label: "List",
            onClick: (e) => navigate("/vehicle"),
          },
          {
            key: "/vehicle/category",
            label: "Category",
            onClick: (e) => navigate("/vehicle/category"),
          },
          {
            key: "/vehicle/feature",
            label: "Feature",
            onClick: (e) => navigate("/vehicle/feature"),
          },
        ],
      },
      perms: ["vehicle_read"],
    },
    {
      item: {
        key: "/cms",
        icon: <CompassOutlined />,
        label: "Cms",
        children: [
          {
            key: "/cms/style",
            label: "Style",
            onClick: (e) => navigate("/cms/style"),
          },
          {
            key: "/cms/layout",
            label: "Layout",
            onClick: (e) => navigate("/cms/layout"),
          },
          {
            key: "/cms/page",
            label: "Page",
            onClick: (e) => navigate("/cms/page"),
          },
          {
            key: "/cms/component",
            label: "Component",
            onClick: (e) => navigate("/cms/component"),
          },
          {
            key: "/cms/element",
            label: "Element",
            onClick: (e) => navigate("/cms/element"),
          },
        ],
      },
      perms: ["cms_read"],
    },
    {
      item: {
        key: "/service-menu",
        icon: <CompassOutlined />,
        label: "Service",
        children: [
          {
            key: "/service/category",
            label: "Category",
            onClick: (e) => navigate("/service/category"),
          },
          {
            key: "/service/feature",
            label: "Feature",
            onClick: (e) => navigate("/service/feature"),
          },
        ],
      },
      perms: ["service_read"],
    },
    {
      item: {
        key: "/geo-menu",
        icon: <CompassOutlined />,
        label: "Geo",
        children: [
          {
            key: "/bound",
            label: "Bound",
            onClick: (e) => navigate("/bound"),
          },
          {
            key: "/station",
            label: "Station",
            onClick: (e) => navigate("/station"),
          },
        ],
      },
      perms: ["geo_read"],
    },
    {
      item: {
        key: "/product",
        icon: <ShoppingCartOutlined />,
        label: "Product",
        onClick: (e) => navigate("/product"),
      },
      perms: ["product_read"],
    },
    {
      item: {
        key: "/order",
        icon: <ShoppingCartOutlined />,
        label: "Order",
        onClick: (e) => navigate("/order"),
      },
      perms: ["order_read"],
    },
    {
      item: {
        key: "/delivery",
        icon: <ShoppingCartOutlined />,
        label: "Delivery",
        onClick: (e) => navigate("/delivery"),
      },
      perms: ["delivery_read"],
    },
    {
      item: {
        key: "/document",
        icon: <IdcardOutlined />,
        label: "Document",
        onClick: (e) => navigate("/document"),
      },
      perms: ["document_read"],
    },
    {
      item: {
        key: "/discount-card",
        icon: <WalletOutlined />,
        label: "DiscountCard",
        onClick: (e) => navigate("/discount-card"),
      },
      perms: ["discount_read"],
    },
    {
      item: {
        key: "/org",
        icon: <ShopOutlined />,
        label: "Organization",
        onClick: (e) => navigate("/org"),
      },
      perms: ["organization_read"],
    },
    {
      item: {
        key: "/blog",
        icon: <ContainerOutlined />,
        label: "Blog",
        onClick: (e) => navigate("/blog"),
      },
      perms: ["blog_read"],
    },
    {
      item: {
        key: "/tag",
        icon: <TagOutlined />,
        label: "Tag",
        onClick: (e) => navigate("/tag"),
      },
      perms: ["tag_read"],
    },
    {
      item: {
        key: "/pricing",
        icon: <MoneyCollectOutlined />,
        label: "Pricing",
        onClick: (e) => navigate("/pricing"),
      },
      perms: ["pricing_read"],
    },
    {
      item: {
        key: "/info-menu",
        icon: <InfoCircleOutlined />,
        label: "Info",
        children: [
          {
            key: "/info/feedback",
            label: "Feedback",
            onClick: (e) => navigate("/info/feedback"),
          },
          {
            key: "/info/contact",
            label: "Contact",
            onClick: (e) => navigate("/info/contact"),
          },
        ],
      },
      perms: ["info_read"],
    },

    {
      item: {
        key: "/super-user-menu",
        icon: <TeamOutlined />,
        label: "Super User",
        children: [
          {
            key: "/auth",
            label: "Role",
            onClick: (e) => navigate("/auth"),
          },
          {
            key: "/user",
            label: "User",
            onClick: (e) => navigate("/user"),
          },
          {
            key: "/device",
            label: "Device",
            onClick: (e) => navigate("/device"),
          },
          {
            key: "/setting",
            label: "Setting",
            onClick: (e) => navigate("/setting"),
          },
          {
            key: "/log",
            label: "Log",
            onClick: (e) => navigate("/log"),
          },
        ],
      },
      perms: ["super_admin"],
    },
  ];

  return (
    <Sider
      theme="light"
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <div style={{ padding: 40 }} className="logo">
        <img src={logo} alt="logo" width={100} />
      </div>
      <Menu
        defaultSelectedKeys={[location.pathname]}
        mode="inline"
        items={sidebars.filter((e) => calculate(e.perms)).map((e) => e.item)}
      />
    </Sider>
  );
};

export default Sidebar;
