import "./App.less";
import { Suspense, lazy } from "react";
import NotFound from "./components/layouts/404";
import { Route, BrowserRouter, Routes as ReactRoutes } from "react-router-dom";
import { AuthContextProvider } from "./modules/auth/context";
import Login from "./modules/user/Login";
import Layout from "./components/layouts/Layout";
import PrivateRoute from "./components/layouts/PrivateRoute";
import SubCategory from "./modules/category/sub-category";
import Loading from "./components/layouts/Loading";
const Auth = lazy(() => import("./modules/auth"));
const Category = lazy(() => import("./modules/category"));
const Package = lazy(() => import("./modules/category/package"));
const Material = lazy(() => import("./modules/category/material"));
const Type = lazy(() => import("./modules/category/type"));
const Spec = lazy(() => import("./modules/category/spec"));
const Unit = lazy(() => import("./modules/category/unit"));
const Product = lazy(() => import("./modules/product"));
const ProductAdd = lazy(() => import("./modules/product/add"));
const ProductEdit = lazy(() => import("./modules/product/edit"));
const VehicleCategory = lazy(() => import("./modules/vehicle/category"));
const Vehicle = lazy(() => import("./modules/vehicle"));
const User = lazy(() => import("./modules/user"));
const UserAddress = lazy(() => import("./modules/user/address"));
const Device = lazy(() => import("./modules/device"));
const Order = lazy(() => import("./modules/order"));
const Bound = lazy(() => import("./modules/bound"));
const Station = lazy(() => import("./modules/station"));
const Org = lazy(() => import("./modules/organization"));
const OrgAddress = lazy(() => import("./modules/organization/address"));
const Banner = lazy(() => import("./modules/banner"));
const Blog = lazy(() => import("./modules/blog"));
const Tag = lazy(() => import("./modules/tag"));
const Feedback = lazy(() => import("./modules/info/feedback"));
const Contact = lazy(() => import("./modules/info/contact"));
const Pricing = lazy(() => import("./modules/pricing"));
const Discount = lazy(() => import("./modules/user/discount"));
const Setting = lazy(() => import("./modules/setting"));
const PricingWeight = lazy(() => import("./modules/pricing/weight"));
const UserDocument = lazy(() => import("./modules/user/document"));
const Log = lazy(() => import("./modules/log"));
const Document = lazy(() => import("./modules/document"));
const Dashboard = lazy(() => import("./modules/dashboard"));
const Delivery = lazy(() => import("./modules/delivery"));
const CmsStyle = lazy(() => import("./modules/cms/styles"));
const CmsLayout = lazy(() => import("./modules/cms/layouts"));
const CmsPage = lazy(() => import("./modules/cms/pages"));
const CmsComponent = lazy(() => import("./modules/cms/components"));
const CmsElement = lazy(() => import("./modules/cms/elements"));

function App() {
  return (
    <AuthContextProvider>
      <div className="App">
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <ReactRoutes>
              <Route
                exact
                path="/login"
                element={
                  <Layout>
                    <Login />
                  </Layout>
                }
              />
              <Route
                path="/"
                element={
                  <PrivateRoute perms={["super_admin"]}>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/banner"
                element={
                  <PrivateRoute perms={["banner_read"]}>
                    <Banner />
                  </PrivateRoute>
                }
              />
              <Route
                path="/category"
                element={
                  <PrivateRoute perms={["category_read"]}>
                    <Category />
                  </PrivateRoute>
                }
              />
              <Route
                path="/sub-category"
                element={
                  <PrivateRoute perms={["sub_category_read"]}>
                    <SubCategory />
                  </PrivateRoute>
                }
              />
              <Route
                path="/package"
                element={
                  <PrivateRoute perms={["package_read"]}>
                    <Package />
                  </PrivateRoute>
                }
              />
              <Route
                path="/material"
                element={
                  <PrivateRoute perms={["material_read"]}>
                    <Material />
                  </PrivateRoute>
                }
              />
              <Route
                path="/type"
                element={
                  <PrivateRoute perms={["type_read"]}>
                    <Type />
                  </PrivateRoute>
                }
              />
              <Route
                path="/spec"
                element={
                  <PrivateRoute perms={["spec_read"]}>
                    <Spec />
                  </PrivateRoute>
                }
              />
              <Route
                path="/unit"
                element={
                  <PrivateRoute perms={["super_admin"]}>
                    <Unit />
                  </PrivateRoute>
                }
              />
              <Route
                path="/vehicle"
                element={
                  <PrivateRoute perms={["vehicle_read"]}>
                    <Vehicle />
                  </PrivateRoute>
                }
              />
              <Route
                path="/vehicle/category"
                element={
                  <PrivateRoute perms={["vehicle_read"]}>
                    <VehicleCategory />
                  </PrivateRoute>
                }
              />
              <Route
                path="/bound"
                element={
                  <PrivateRoute perms={["geo_read"]}>
                    <Bound />
                  </PrivateRoute>
                }
              />
              <Route
                path="/station"
                element={
                  <PrivateRoute perms={["geo_read"]}>
                    <Station />
                  </PrivateRoute>
                }
              />
              <Route
                path="/product"
                element={
                  <PrivateRoute perms={["product_read"]}>
                    <Product />
                  </PrivateRoute>
                }
              />
              <Route
                path="/products/add"
                element={
                  <PrivateRoute perms={["product_create"]}>
                    <Product />
                  </PrivateRoute>
                }
              />
              <Route
                path="/products/:id/edit"
                element={
                  <PrivateRoute perms={["product_update"]}>
                    <Product />
                  </PrivateRoute>
                }
              />
              <Route
                path="/delivery"
                element={
                  <PrivateRoute perms={["delivery_read"]}>
                    <Delivery />
                  </PrivateRoute>
                }
              >
                <Route
                  path="/delivery/create"
                  element={
                    <PrivateRoute perms={["delivery_create"]}></PrivateRoute>
                  }
                />
                <Route
                  path="/delivery/:id/edit"
                  element={
                    <PrivateRoute perms={["delivery_edit"]}></PrivateRoute>
                  }
                />
              </Route>
              <Route
                path="/document"
                element={
                  <PrivateRoute perms={["document_read"]}>
                    <Document />
                  </PrivateRoute>
                }
              />
              <Route
                path="/org"
                element={
                  <PrivateRoute perms={["organization_read"]}>
                    <Org />
                  </PrivateRoute>
                }
              />
              <Route
                path="/org/:id/address"
                element={
                  <PrivateRoute perms={["organization_read"]}>
                    <OrgAddress />
                  </PrivateRoute>
                }
              />

              <Route
                path="/blog"
                element={
                  <PrivateRoute perms={["blog_read"]}>
                    <Blog />
                  </PrivateRoute>
                }
              />
              <Route
                path="/tag"
                element={
                  <PrivateRoute perms={["tag_read"]}>
                    <Tag />
                  </PrivateRoute>
                }
              />
              <Route
                path="/pricing"
                element={
                  <PrivateRoute perms={["pricing_read"]}>
                    <Pricing />
                  </PrivateRoute>
                }
              />
              <Route
                path="/pricing/:id/weight"
                element={
                  <PrivateRoute perms={["pricing_read"]}>
                    <PricingWeight />
                  </PrivateRoute>
                }
              />

              <Route
                path="/info/feedback"
                element={
                  <PrivateRoute perms={["info_read"]}>
                    <Feedback />
                  </PrivateRoute>
                }
              />
              <Route
                path="/info/contact"
                element={
                  <PrivateRoute perms={["info_read"]}>
                    <Contact />
                  </PrivateRoute>
                }
              />
              <Route
                path="/user"
                element={
                  <PrivateRoute perms={["user_read"]}>
                    <User />
                  </PrivateRoute>
                }
              />

              <Route
                path="/user/:id/address"
                element={
                  <PrivateRoute perms={["user_read"]}>
                    <UserAddress />
                  </PrivateRoute>
                }
              />

              <Route
                path="/device"
                element={
                  <PrivateRoute perms={["device_read"]}>
                    <Device />
                  </PrivateRoute>
                }
              />

              <Route
                path="/auth"
                element={
                  <PrivateRoute perms={["device_read"]}>
                    <Auth />
                  </PrivateRoute>
                }
              />

              <Route
                path="/user/:id/discount"
                element={
                  <PrivateRoute perms={["user_discount_read"]}>
                    <Discount />
                  </PrivateRoute>
                }
              />

              <Route
                path="/user/:id/document"
                element={
                  <PrivateRoute perms={["user_discount_read"]}>
                    <Document />
                  </PrivateRoute>
                }
              />

              <Route
                path="/setting"
                element={
                  <PrivateRoute perms={["settings_read"]}>
                    <Setting />
                  </PrivateRoute>
                }
              />
              <Route
                path="/log"
                element={
                  <PrivateRoute perms={["log_read"]}>
                    <Log />
                  </PrivateRoute>
                }
              />

              <Route
                path="/cms/style"
                element={
                  <PrivateRoute perms={["cms_read"]}>
                    <CmsStyle />
                  </PrivateRoute>
                }
              />
              <Route
                path="/cms/layout"
                element={
                  <PrivateRoute perms={["cms_read"]}>
                    <CmsLayout />
                  </PrivateRoute>
                }
              />
              <Route
                path="/cms/page"
                element={
                  <PrivateRoute perms={["cms_read"]}>
                    <CmsPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/cms/component"
                element={
                  <PrivateRoute perms={["cms_read"]}>
                    <CmsComponent />
                  </PrivateRoute>
                }
              />

              <Route
                path="/cms/element"
                element={
                  <PrivateRoute perms={["cms_read"]}>
                    <CmsElement />
                  </PrivateRoute>
                }
              />

              <Route path="*" element={<NotFound />} />
            </ReactRoutes>
          </Suspense>
        </BrowserRouter>
      </div>
    </AuthContextProvider>
  );
}

export default App;
